import React, { Component } from "react";
import Page from "../../components/page/Page";
import { withTranslation } from "react-i18next";
import {
  Button,
  Form,
  Grid,
  Header,
  Icon,
  Message,
  Segment,
} from "semantic-ui-react";
import PasswordChangeModal from "./PasswordChangeModal";
import AuthService from "../../services/AuthService";
import ConfigService from "../../services/ConfigService";
import SubjectService from "../../SubjectService";
import InternationalisationService from "../../InternationalisationService";
import TrialService from "../../TrialService";
import AppStateService, {frontendCacheKeys} from "../../services/AppStateService";
import PermissionsService from "../../services/PermissionsService";


export class Profile extends Component {
  state = {
    originalFirstName: "",
    originalLastName: "",
    originalEmail: "",
    firstName: "",
    lastName: "",
    email: "",
    superAdmin: false,
    isPrimarySuperAdmin: false,
    loading: true,
    showPasswordChangeModal: false,
    canChange: false,
    subjectCode: "",
    autheticationStrat: "",
  };

  constructor(props, context) {
    super(props, context);
    const { location } = props;

    this.checkIfAllowedToChangePassword();
    this.getAuthenticationStratergy();

    AuthService.getMyProfile()
      .then((profile) => {
        this.setState({
          originalFirstName: profile.firstName,
          originalLastName: profile.lastName,
          originalEmail: profile.email,
          firstName: profile.firstName,
          lastName: profile.lastName,
          email: profile.email,
          superAdmin: profile.superAdmin,
          loading: false,
        });

        const accountType = AuthService.getAccountType();
        if (accountType === 'subject'){
          SubjectService.getSubjectCode()
            .then(code => {
              this.setState({
                subjectCode: code
              });
            })
        }

        if (accountType === 'staff'){
          this.setState({isPrimarySuperAdmin: PermissionsService.isPrimarySuperAdmin(profile)})
        }

        const query = new URLSearchParams(location.search);
        const forcePasswordChangeAtLogin = query.get("changePassword") !== null; // Check for the presence of the query item, the value is irrelevant
        if (forcePasswordChangeAtLogin) {
          this.setState({ showPasswordChangeModal: true });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  onSubmit = (event) => {
    this.setState({ submitting: true });

    return AuthService.updateMyProfile(
      this.state.email,
      this.state.firstName,
      this.state.lastName
    )
      .then(() => {
        this.goHome();
      })
      .catch((e) => {
        const actualError = Array.isArray(e) && e.length > 0 ? e[0] : e;
        this.handleError(actualError);
      })
      .finally(() => this.setState({ submitting: false }));
  };

  handleError = (e) => {
    let messageText;
    const { t } = this.props;
    const accountType = AuthService.getAccountType();
    if (accountType === "staff") {
      if (e?.message?.indexOf("Email exists") >= 0) {
        messageText = t("STAFF_EMAIL_EXISTS_ERROR_MESSAGE");
      }
      if (e?.message?.indexOf("Must include at least one group") >= 0) {
        messageText = t("STAFF_NO_GROUP_ROLES_ERROR_MESSAGE", "Please enter one or more site roles for this staff member");
      }
      if (e?.message?.indexOf("All groups must include at least one role") >= 0) {
        messageText = t("STAFF_NO_ROLES_IN_GROUP_ERROR_MESSAGE", "All selected sites must have at least one role");
      }
    }
    if (!messageText) {
      messageText = t(
        "GLOBAL_ERROR_GENERIC_FORM_MESSAGE",
        "Please fill in all the required fields"
      );
    }
    this.setState({ errorMessage: messageText });
  };

  checkIfAllowedToChangePassword = () => {
    SubjectService.canChangePassword()
      .then((data) => {
        this.setState({
          canChange: data
        });
      });
  }

  getAuthenticationStratergy = () => {
    TrialService.getSubjectAuthenticationStrategy()
      .then(authStrat => {
        this.setState({autheticationStrat: authStrat});
      })
  }

  validateEmail = () => {
    return AuthService.EMAIL_REGEX.test(this.state.email);
  };

  goHome = () => {
    if( AuthService.isStaff() ) {
      window.location.href = window.location.origin + "/app/home";
      return;
    }
    window.location.href = window.location.origin;
  };

  onCancel = () => {
    this.goHome();
  };

  changePassword = () => this.setState({ showPasswordChangeModal: true });

  clearQuestionnaireProgression = () => {
    AppStateService.clearFrontendCache(frontendCacheKeys.CURRENT_QUESTIONNAIRE)
    // eslint-disable-next-line no-restricted-globals
    location.reload()
  }

  inputChanged = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  isModified = () => {
    return (
      this.state.firstName !== this.state.originalFirstName ||
      this.state.lastName !== this.state.originalLastName ||
      this.state.email !== this.state.originalEmail
    );
  };

  handleModalClose = () => {
    this.setState({ showPasswordChangeModal: false })
    this.checkIfAllowedToChangePassword();
  };

  render() {
    const { t } = this.props;
    const { firstName, lastName, email } = this.state;
    const accountType = AuthService.getAccountType();

    const isSubject = accountType === "subject";
    const isStaff = accountType === "staff";
    let includeSaveCancelButtons = true;

    const showEditForm =
      isStaff || (isSubject && ConfigService.canSubjectViewAndEditOwnEmail());

    const getIsFormEditable = () => {
      if (isSubject) {
        return ConfigService.canSubjectViewAndEditOwnEmail();
      }

      if (isStaff) {
        if (this.state.isPrimarySuperAdmin) {
          return false;
        }
        return ConfigService.canStaffEditOwnDetails();
      }

      return true;
    }

    const isFormEditable = getIsFormEditable();

    const changePasswordButtonText = isStaff
      ? t(
          [
            "PROFILE_BUTTON_CHANGE_PASSWORD_STAFF",
            "PROFILE_BUTTON_CHANGE_PASSWORD",
          ],
          "Change Password"
        )
      : t(
          [
            "PROFILE_BUTTON_CHANGE_PASSWORD_SUBJECT",
            "PROFILE_BUTTON_CHANGE_PASSWORD",
          ],
          "Change Password"
        );

    return (
      <Page
        name="PROFILE"
        header={t("PROFILE_HEADER")}
        subheader={t("PROFILE_SUBHEADER")}
        loading={this.state.loading}
      >
        {isStaff && !isFormEditable && this.state.isPrimarySuperAdmin && (
          <Message error visible size="large">
            <Message.Header>
              <Icon name={"warning sign"} /> WARNING
            </Message.Header>
            {t("YOU_ARE_PRIMARY_SUPERADMIN_PROFILE_WARNING", "You are logged in as the Primary Super Admin, and cannot edit these details.")}
          </Message>
        )}
        {isStaff && !isFormEditable && !this.state.isPrimarySuperAdmin && (
          <Message warning visible size="large">
            <Message.Header>
              <Icon name={"info circle"} /> {t("PROFILE_STAFF_CANNOT_EDIT_HEADER", "You cannot edit these details")}
            </Message.Header>
            {t("PROFILE_STAFF_CANNOT_EDIT_MESSAGE", "Only system administrators can edit these details")}
          </Message>
        )}

        {showEditForm && (
          <Form onSubmit={this.onSubmit} onCancel={this.onCancel}>
            <Message
              visible={this.state.errorMessage !== undefined}
              error
              header={t("STAFF_FORM_ERROR_TITLE")}
            >
              {this.state.errorMessage}
            </Message>

            <Grid columns={"equal"}>
              <Grid.Row>
                <Grid.Column
                  textAlign={
                    InternationalisationService.isRTL() ? "right" : undefined
                  }
                >
                  <Segment>
                    <Header>{t("STAFF_FORM_DETAILS_HEADER")}</Header>
                    {
                      (() => {
                      let toRender;
                      let firstNameLastName = (<>
                      <Form.Field>
                        <label>{t("STAFF_FORM_FIRSTNAME_LABEL")}</label>
                        <input
                          autoFocus
                          name={"firstName"}
                          required
                          value={firstName}
                          onChange={this.inputChanged}
                          disabled={!isFormEditable}
                        />
                      </Form.Field>
                      <Form.Field>
                        <label>{t("STAFF_FORM_LASTNAME_LABEL")}</label>
                        <input
                          name={"lastName"}
                          required
                          value={lastName}
                          onChange={this.inputChanged}
                          disabled={!isFormEditable}
                        />
                      </Form.Field>
                      </>)
                      let emailField =
                        (<Form.Field>
                          <label>{t("STAFF_FORM_EMAIL_LABEL")}</label>
                          <input
                            name={"email"}
                            required
                            value={email}
                            onChange={this.inputChanged}
                            disabled={!isFormEditable}
                          />
                        </Form.Field>)
                      if (accountType === "staff") {
                        toRender = [firstNameLastName, emailField] ;
                      }else{
                        if (this.state.autheticationStrat !== 'SUBJECTCODE_PWD') {
                          toRender = emailField;
                        }else{
                          includeSaveCancelButtons = false;
                          toRender = <label>{t("PROFILE_SUBJECT_CODE","Subject Code")}: {this.state.subjectCode}</label>
                        }
                      }
                      return toRender;
                      })()
                    }
                  </Segment>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column
                  textAlign={
                    InternationalisationService.isRTL() ? "right" : undefined
                  }
                >
                 {includeSaveCancelButtons && (<><Button
                    type="submit"
                    disabled={!this.validateEmail() || !isFormEditable}
                    loading={this.state.submitting}
                    primary
                  >
                    {t("GLOBAL_BUTTON_SAVE", "Save")}
                  </Button>
                  <Button primary basic type="button" onClick={this.onCancel}>
                    {t("GLOBAL_BUTTON_CANCEL", "Cancel")}
                  </Button></>)}
                  {this.state.canChange && (<Button
                    primary
                    basic
                    type="button"
                    floated="right"
                    onClick={this.changePassword}
                  >
                    {changePasswordButtonText}
                  </Button>)}
                  {this.state.superAdmin && (
                    <Button
                      primary
                      basic
                      type="button"
                      floated="right"
                      onClick={() => {
                        this.props.history.push("/app/utils");
                      }}
                    >
                      {t("ADMIN_SECTION", "Admin Utils")}
                    </Button>
                  )}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        )}

        {!showEditForm && this.state.canChange && (
          <Button primary basic type="button" onClick={this.changePassword}>
            {changePasswordButtonText}
          </Button>
        )}

        {ConfigService.isUsingFrontendStateCache() && (
          <Button primary basic type="button" onClick={this.clearQuestionnaireProgression}>
            {t(
                "RESET_QUESTIONNAIRE_PROGRESS_BUTTON",
                "Reset Questionnaire Progress"
            )}
          </Button>
        )}

        {!this.state.canChange && (
          <Segment style={{ overflow: "auto" }}>
            <h4>
              {t(
                "PASSWORD_HAS_BEEN_CHANGED_TOO_RECENTLY",
                "Password changed too many times in allowed period, please try again later"
              )}
            </h4>
          </Segment>
        )}

        <PasswordChangeModal
          show={this.state.showPasswordChangeModal}
          onClose={this.handleModalClose}
          closeIcon
        />
      </Page>
    );
  }
}

export default withTranslation()(Profile);
