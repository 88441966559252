import React, { useState } from "react";
import { Button, Confirm } from "semantic-ui-react";
import GeneralHelpers from "../../helpers/GeneralHelpers";

const ConfirmButton = (props) => {
  const {
    onConfirm,
    onCancel,
    buttonText,
    headerText,
    contentText,
    confirmButtonText,
    cancelButtonText,
    size,
    color,
    disabled,
    preventDefault,
    labelPosition,
    floated
  } = props;

  const [isOpen, setIsOpen] = useState(false);

  const handleConfirm = () => {
    setIsOpen(false);
    if (onConfirm) {
      onConfirm();
    }
  };

  const handleCancel = () => {
    setIsOpen(false);
    if (onCancel) {
      onCancel();
    }
  };

  const theButton = (
    <Button
      floated={floated}
      labelPosition={labelPosition}
      color={color ? color : "orange"}
      disabled={disabled}
      size={size}
      icon
      onClick={(e) => {
        if (preventDefault) {
          GeneralHelpers.stopEvent(e);
        }
        setIsOpen(true);
      }}
    >
      {buttonText}
      {props.children}
    </Button>
  );

  return (
    <>
      {theButton}
      <Confirm
        open={isOpen}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
        header={headerText}
        content={contentText}
        confirmButton={confirmButtonText}
        cancelButton={{ content: cancelButtonText, primary: true, basic: true }}
        size={size}
      />
    </>
  );
};

export default ConfirmButton;
