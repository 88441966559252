import React, { useCallback, useState } from "react";
import { Accordion, Icon } from "semantic-ui-react";

function AccordianReveal({ children, displayText, noPadding, maxHeight }) {
  const [isOpen, setIsOpen] = useState();

  const toggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  return (
    <Accordion>
      <Accordion.Title style={{
        color: "orange",
        paddingBottom: noPadding ? "0" : undefined,
        paddingTop: noPadding ? "0" : undefined,
      }} onClick={toggle}>
        <div
          style={{
            display: "flex",
            width: "100%",
            pointerEvents: "none",
            paddingLeft: noPadding ? undefined : "0.5rem",
          }}
        >
          <Icon
            name="dropdown"
            style={{
              rotate: isOpen ? "90deg" : "0deg",
              alignSelf: "center",
            }}
          />
          <label style={{ flex: 1 }}>
            <b>{displayText}</b>
          </label>
        </div>
      </Accordion.Title>
        { isOpen && (<Accordion.Content style={{ padding: noPadding ? undefined : "1rem", maxHeight: maxHeight ? maxHeight : undefined, overflowY: maxHeight ? 'scroll' : undefined }} active={isOpen}>
        {children}
      </Accordion.Content>)}
    </Accordion>
  );
}

export default AccordianReveal;
