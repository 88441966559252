import React, { useEffect, useState } from "react";
import { Button, Form, Grid, Header } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import InternationalisationService from "../../InternationalisationService";
import AparitoSwitch from "../questionnaire/AparitoSwitch";

const PickLanguageModalInsert = (props) => {
  const { t, onLanguageSelected, onPickedLanguage } = props;

  const [languages, setLanguages] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [showNoneSelectedScreen , setShowNoneSelectedScreen] = useState(false);

  const NONE_OPTION = {
    code: "none",
    name: t("LANGUAGE_PICKER_NONE"),
  };

  const changeLanguage = (language) => {
    setSelectedLanguage(language);

    let newLanguage = language;
    if (newLanguage.code === NONE_OPTION.code) {
      newLanguage = languages[0];
    }
    onLanguageSelected(newLanguage);
  }

  const handleSubmit = () => {
    if (selectedLanguage.code !== NONE_OPTION.code) {
      onPickedLanguage(selectedLanguage);
    } else {
      setShowNoneSelectedScreen(true);
    }
  };

  const loadLanguages = async () => {
    const languages = await InternationalisationService.getLanguages();
    setLanguages(languages);
  };

  useEffect(() => {
    loadLanguages();
  }, []);

  if (showNoneSelectedScreen) {
    return (
      <Form style={styles.container}>
        <Grid centered padded>
          <Grid.Row columns={3}>
            <Grid.Column
              width={10}
              textAlign={
                InternationalisationService.isRTL() ? "right" : undefined
              }
            >
              <Header as="h2">{t("LANGUAGE_PICKER_TITLE")}</Header>
              <p>{t("LANGUAGE_PICKER_NONE_INFO")}</p>
              <Button
                fluid
                size="large"
                onClick={() => setShowNoneSelectedScreen(false)}
              >
                {t("GLOBAL_BUTTON_BACK")}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Grid centered padded>
          <Grid.Row>
            <Button
              className="borderless"
              basic
              color={"orange"}
              size="small"
              as={"a"}
              href="https://www.aparito.com/privacy-policy/"
              target="_blank"
            >
              {t("PRIVACY_POLICY")}
            </Button>
          </Grid.Row>
        </Grid>
      </Form>
    );
  }

  return (
    <Form style={styles.container} onSubmit={handleSubmit}>
      <Grid centered padded>
        <Grid.Row columns={3}>
          <Grid.Column
            width={10}
            textAlign={
              InternationalisationService.isRTL() ? "right" : undefined
            }
          >
            <Header as="h2">{t("LANGUAGE_PICKER_TITLE")}</Header>
            <h3>{t("LANGUAGE_PICKER_LABEL")}</h3>

            <div style={{ paddingBottom: 20 }}>
              {[...languages, NONE_OPTION].map((language) => {
                return (
                  <AparitoSwitch
                    key={language.code}
                    checked={
                      selectedLanguage &&
                      selectedLanguage.code === language.code
                    }
                    onChange={() => changeLanguage(language)}
                    label={language.name}
                  />
                );
              })}
            </div>

            <Button
              type="submit"
              primary
              fluid
              size="large"
              disabled={selectedLanguage == null}
            >
              {t("GLOBAL_BUTTON_NEXT")}
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid centered padded>
        <Grid.Row>
          <Button
            className="borderless"
            basic
            color={"orange"}
            size="small"
            as={"a"}
            href="https://www.aparito.com/privacy-policy/"
            target="_blank"
          >
            {t("PRIVACY_POLICY")}
          </Button>
        </Grid.Row>
      </Grid>
    </Form>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
};

const enhance = compose(withTranslation());

export default enhance(PickLanguageModalInsert);
