import GroupPermission from "../GroupPermission";
import PermissionsService from "./PermissionsService";

const StaffPermissionService = {
  canManageRoles: (profile) => {
    return PermissionsService.isPrimarySuperAdmin(profile)
      || PermissionsService.hasPermissionInAnyGroupSynchronous(profile, GroupPermission.MANAGE_ROLES);
  },
  canManageRemoteJobProcessingQueues: (profile) => {
    return PermissionsService.hasPermissionInAnyGroupSynchronous(profile, GroupPermission.MANAGE_REMOTEJOB_PROCESSINGQUEUES);
  },
  canViewAttachmentMonitoring: (profile) => {
    return PermissionsService.hasPermissionInAnyGroupSynchronous(profile, GroupPermission.VIEW_ATTACHMENT_MONITORING);
  },
  canManualAttachmentBlurringStatusUpdate: (profile) => {
    return PermissionsService.hasPermissionInAnyGroupSynchronous(profile, GroupPermission.MANUAL_ATTACHMENT_BLURRING_STATUS_UPDATE);
  },
  canViewAttachmentsForRandomising: (profile) => {
    return PermissionsService.hasPermissionInAnyGroupSynchronous(profile, GroupPermission.VIEW_ATTACHMENTS_FOR_RANDOMISING);
  },
  canRefreshCaches: (profile) => {
    return PermissionsService.hasPermissionInAnyGroupSynchronous(profile, GroupPermission.REFRESH_CACHES);
  },
  canManageTriggers: (profile) => {
    return PermissionsService.hasPermissionInAnyGroupSynchronous(profile, GroupPermission.MANAGE_TRIGGERS);
  },
  canManageConfig: (profile) => {
    return PermissionsService.hasPermissionInAnyGroupSynchronous(profile, GroupPermission.MANAGE_CONFIG);
  },
  canManageExportConfig: (profile) => {
    return PermissionsService.hasPermissionInAnyGroupSynchronous(profile, GroupPermission.MANAGE_EXPORT_CONFIG);
  },
  canManageTrialLevelConfig: (profile) => {
    return PermissionsService.hasPermissionInAnyGroupSynchronous(profile, GroupPermission.MANAGE_TRIAL_LEVEL_CONFIG);
  },
  canManageDataSegregation: (profile) => {
    return PermissionsService.hasPermissionInAnyGroupSynchronous(profile, GroupPermission.MANAGE_DATA_REPLICATION);
  },
  canManageLookups: (profile) => {
    return PermissionsService.hasPermissionInAnyGroupSynchronous(profile, GroupPermission.MANAGE_LOOKUPS);
  },
  canPerformAnswerRecalculation: (profile) => {
    return PermissionsService.hasPermissionInAnyGroupSynchronous(profile, GroupPermission.PERFORM_ANSWER_RECALCULATION);
  },
  canSearchNotificationActivityAudit: (profile) => {
    return PermissionsService.hasPermissionInAnyGroupSynchronous(profile, GroupPermission.SEARCH_NOTIFICATION_ACTIVITY_AUDIT);
  },
  canAccessTechSupportFeatures: (profile) => {
    return PermissionsService.hasPermissionInAnyGroupSynchronous(profile, GroupPermission.ACCESS_TECH_SUPPORT_FEATURES);
  },
  canManageTranslations: (profile) => {
    return PermissionsService.hasPermissionInAnyGroupSynchronous(profile, GroupPermission.MANAGE_TRANSLATIONS);
  },
  canManageQuestionnaireWorkflows: (profile) => {
    return PermissionsService.hasPermissionInAnyGroupSynchronous(profile, GroupPermission.MANAGE_QUESTIONNAIRE_WORKFLOWS);
  },
  canManageModules: (profile) => {
    return PermissionsService.hasPermissionInAnyGroupSynchronous(profile, GroupPermission.MANAGE_MODULES);
  },
  canManageQuestionnaireDefinitions: (profile) => {
    return PermissionsService.hasPermissionInAnyGroupSynchronous(profile, GroupPermission.MANAGE_QUESTIONNAIRE_DEFINITIONS);
  },
  Reports: {
    canAccessVisitComplianceReport: (profile) => {
      return PermissionsService.hasPermissionInAnyGroupSynchronous(profile, GroupPermission.REPORTS_VISIT_COMPLIANCE);
    },
    canAccessManualDataChangeReport: (profile) => {
      return PermissionsService.hasPermissionInAnyGroupSynchronous(profile, GroupPermission.REPORTS_MANUAL_DATA_CHANGE);
    }
  }
};

export default StaffPermissionService;
