import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import { withTranslation } from "react-i18next";
import SubjectService from "../../SubjectService";
import Page from "../../components/page/Page";
import {
  Button,
  Form,
  Grid,
  Message,
  Segment,
  TextArea,
  Header,
  Icon, Dropdown, Checkbox, Input, List
} from "semantic-ui-react";
import TriggerService from "../../services/admin/TriggerService";
import {getDefinitions} from "../../redux/questionnaires/questionnaireDefinitionsSlice";
import {compose} from "redux";
import {connect} from "react-redux";
import isTriggerValidForManualDelivery from "./utility/isTriggerValidForManualDelivery";
import PermissionsService from "../../services/PermissionsService";
import StaffService from "../../StaffService";
import ConfigContext from "../../context/ConfigContext";
import VisitDateService from "../../services/VisitDateService";
import GroupPermission from "../../GroupPermission";
import UserContext from "../../context/UserContext";


const SubjectCreateQuestionnairePage = ({t, match, history, definitions}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasViewSubjectVisitDatesPermission, setHasViewSubjectVisitDatesPermission] = useState(false);
  
  const user = useContext(UserContext);
  const isSuperAdmin = user.profile.superAdmin;

  // handle definition
  const definition = useMemo(()=>definitions.find(
      d => d.code === match.params.definitionCode
  ),[definitions, match.params.definitionCode]);
  const [customLabels, setCustomLabels] = useState(
      () => definition?.questions.map(
          (q) => {
            if (q?.config?.isCustomisable) {
              return ({
                code: q.code,
                label: "",
              });
            }
            return null;
          }).filter(q => q !== null)
  );
  const areCustomLabelsComplete = useMemo(
      ()=> Array.isArray(customLabels) &&
          customLabels.every(
              (label) => label.label.length > 0
          )
      ,[customLabels]);

  const config = useContext(ConfigContext);

  // handle async loading
  const [staffProfile, setStaffProfile] = useState(null);
  const [subjectData, setSubjectData] = useState(null);
  const [triggers, setTriggers] = useState(null);
  const [parent, setParent] = useState(null);
  const [subjectVisitDates, setSubjectVisitDates] = useState([]);

  const [search, setSearch] = useState('');
  const [filteredItems, setFilteredItems] = useState([]);

  const parentOptions = useMemo(() => parent?.map(p => (
    {
      key: p.id,
      text: p.id + " | " + p.type,
      value: p.id,
    }
  )), [parent]);

  const triggerOptions = useMemo(() => triggers?.map(t => (
    {
      key: t.id,
      text: t.name + " | " + t.trigger + ((t.condition != null) ? " | " + t.condition : ""),
      value: t.id,
    }
  )), [triggers]);
  const initialise = useCallback(async () => {
    try {
      const [staffProfile, subjectData, triggers, parent] = await Promise.all([
        StaffService.getMyProfile(),
        SubjectService.getSubjectData(match.params.subjectId),
        TriggerService.getTriggersByActionContainsQuestionnaireDefinition(match.params.definitionCode),
        SubjectService.getSubjectQuestionnairesAllById(match.params.subjectId)
      ]);

      setTriggers(triggers
          .filter(trigger => {
            return (trigger.action.includes("questionnaire://" + match.params.definitionCode)) ||
                (trigger.action.includes("reward://" + match.params.definitionCode))
          })
      )
    setParent(parent);
    setSubjectData(subjectData);

      const hasViewVisitDatesPerm = await PermissionsService.hasPermissionForSubject(
        subjectData?.groups,
        GroupPermission.VIEW_SUBJECT_VISITDATES);
      setHasViewSubjectVisitDatesPermission(hasViewVisitDatesPerm);

      setStaffProfile(staffProfile);

      if (hasViewVisitDatesPerm) {
        setSubjectVisitDates(await VisitDateService.getVisitDatesForSubject(match.params.subjectId));
      }
    } catch (error) {
      setHasError(true);
    }
  }, [match.params.subjectId, match.params.definitionCode]);
  useEffect(initialise, [initialise]);

  
  const handleParentSearch = (event) => {
    const query = event.target.value;
    setSearch(query);

    if (query.length >= 3) {
      const results = parentOptions.filter(item => item.text.toLowerCase().includes(query.toLowerCase()));
      setFilteredItems(results)
    } else {
      setFilteredItems([])
    }
  };

  // handle user input and errors
  const [activationWindowInDays, setActivationWindowInDays] = useState(1)
  const [selectedTriggerId, setSelectedTriggerId] = useState(null);
  const [selectedParentId, setSelectedParentId] = useState(null);
  const selectedTrigger = useMemo(()=>triggers?.find(t=>t.id===selectedTriggerId), [selectedTriggerId, triggers]);
  const isTriggerValid = useMemo(()=>isTriggerValidForManualDelivery(selectedTriggerId), [selectedTriggerId]);
  const [selectedModules, setSelectedModules] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [selectedVisitId, setSelectedVisitid] = useState();

  const errorMessage = hasError
      ? t("GLOBAL_ERROR_GENERIC_MESSAGE")
      : undefined;
  const handleActivationWindowInDaysChange = (event) => {
    setActivationWindowInDays(event.target.value)
  };
  const onSubmit = () => {
    if(isSubmitting) {
      return;
    }
    if(!isTriggerValid){
      setHasError(true);
      return;
    }
    setIsSubmitting(true);

    let queryParams = {
      activationWindow: "P" + activationWindowInDays + "D",
    };
    if (selectedTriggerId || selectedParentId){
      const modules = definition?.config?.modules?.map((m,i)=>{
        if(selectedModules[i]){
          return m
        }
        return null;
      }).filter(m=>m!==null);

      if(selectedTriggerId){
        queryParams.triggerid = selectedTriggerId
      }

      if(selectedParentId){
        queryParams.parentQuestionnaire = selectedParentId
      }

      if(modules){
        queryParams.modules = modules
      }
    }

    if (selectedVisitId != null && selectedVisitId !== 'NONE_SELECTED') {
      queryParams.visitId = selectedVisitId;
    }

    const subjectData = {};
    if (customLabels.length > 0) {
      queryParams.customLabels = true;
      subjectData.customLabels = customLabels;
    }

    return SubjectService.createQuestionnaire(
        match.params.subjectId,
        match.params.definitionCode,
        subjectData,
        queryParams
    ).then(() => {
      setIsSubmitting(false);
      handleCancel();
    }).catch(() => {
      setIsSubmitting(false);
      setHasError(true);
    });
  };
  const handleTriggerChange = (e, data) => {
    setSelectedTriggerId(data.value);
  }
  const handleModuleChange = (index, data) => {
    setSelectedModules(previousSelectedModules => {
      let newSelectedValues = [...previousSelectedModules];
      newSelectedValues[index] = data.checked;
      return newSelectedValues;
    })
  }
  const handleCustomLabelChange = (questionCode, value) => {
    setCustomLabels((previousLabels)=>{
      return previousLabels.map((customLabel) => {
        if (customLabel.code !== questionCode) return customLabel;
        return {
          code: customLabel.code,
          label: value,
        };
      });
    })
  };

  const handleParentChange = (item) => {
    setSelectedParentId(item.value);
  };
  
  const handleCancel = () => {
    history.goBack();
  };
  useEffect(()=>{setSelectedModules(definition?.config?.modules?.map(m=>false)||[])}, [definition])


  // hadLoaded check
  const hasLoaded = useMemo(()=>{
    if(!definition) return false;
    if(!triggers) return false;
    if(!subjectData) return false;
    if(!staffProfile) return false;
    if(!parent) return false;
    return true;
  },[definition, subjectData, triggers, staffProfile, parent]);

  const buildModuleCheckboxes = () => {
    if(!hasLoaded) return null;
    if(!selectedTrigger?.moduleSpecification) return null;
    if(!definition?.config?.modules) return null;
    if(selectedTrigger?.moduleSpecification === '[]') return null;

    const buildHasPermissionFilter = (tab) => {
      const tabsConfig = config.ui.tabs;
      const fullTabName = "module/"+tab;
      const tabConfig = tabsConfig.find(tC => fullTabName === tC || fullTabName === tC.name);
      if(!tabsConfig) return false;
      return PermissionsService.canStaffViewSubjectTabSynchronous(
          staffProfile,
          subjectData.groups,
          tabConfig,
          false
      );
    }

    return definition?.config?.modules.filter(buildHasPermissionFilter).map((m, i) => (
        <div style={{padding: "8px"}}>
          <Checkbox
              key={"moduleCheckbox_"+m}
              value={m}
              label={t("SUBJECT_TAB_MODULE_" + m.toUpperCase().replace("/", "_"))}
              onChange={(e, d)=>handleModuleChange(i, d)}
          />
        </div>

    ));
  }

  const populateVisitListOptions = () => {
    const options = (subjectVisitDates || []).map(v => {
      const scheduledItem = v.scheduledItem;
      return {
        key: scheduledItem.id,
        text: scheduledItem.name,
        value: scheduledItem.id
      }
    });
    options.unshift(
      {
        key: 'NONE_SELECTED',
        text: t("GENERIC_TERM_NONE", "None"),
        value: 'NONE_SELECTED'
      });
    return options;
  };

  const visitListOptions = useMemo(populateVisitListOptions, [subjectVisitDates, t]);

  return (
      <Page
          name="CREATE_QUESTIONNAIRE"
          header={() => (
              <Header as="h3">
                <Button
                    color="orange"
                    style={{padding: "0.25rem 1rem "}}
                    onClick={() => history.goBack()}
                >
                  <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                  >
                    <Icon name="arrow left"/>
                    <h3 style={{margin: "0rem", marginLeft: "0.5rem"}}>
                      {subjectData?.subjectCode}
                    </h3>
                  </div>
                </Button>
                {" " + t("MANUAL_CREATE_QUESTIONNAIRE_TITLE")}
              </Header>
          )}
          loading={!hasLoaded}
      >
        {hasLoaded && <Form
            onSubmit={onSubmit}
            onCancel={handleCancel}
            error={hasError}
        >
          <Message
              error
              header={t("GLOBAL_ERROR_TITLE")}
              content={errorMessage}
          />
          <Message content={t("MANUAL_CREATE_QUESTIONNAIRE_INFO_MESSAGE")}/>
          <Grid columns={"equal"}>
            <Grid.Column>
              <Segment>
                <>
                  <Form.Field className={"disabled"}>
                    <label>
                      {t("MANUAL_CREATE_QUESTIONNAIRE_DEFINITION_TITLE")}
                    </label>
                    <input disabled value={definition?.label}/>
                  </Form.Field>
                  <Form.Field className={"disabled"}>
                    <label>
                      {t("MANUAL_CREATE_QUESTIONNAIRE_SUBJECT_CODE")}
                    </label>
                    <input disabled value={subjectData?.subjectCode}/>
                  </Form.Field>
                  <Form.Field>
                    <label>
                      {t(
                          "MANUAL_CREATE_QUESTIONNAIRE_ACTIVATION_WINDOW_DAYS"
                      )}
                    </label>
                    <input
                        type="number"
                        name="activationWindowDays"
                        required
                        value={activationWindowInDays}
                        onChange={handleActivationWindowInDaysChange}
                    />
                  </Form.Field>
                <Form.Field>
                  <label>
                    {t(
                      "MANUAL_CREATE_QUESTIONNAIRE_ADD_PARENT_ID", "Parent ID"
                    )}
                  </label>
                  {isSuperAdmin && !selectedParentId && (
                    <Input
                      value={search}
                      onChange={handleParentSearch}
                      placeholder="Search..."
                      fluid
                    />
                  )}
                  {isSuperAdmin && selectedParentId && (
                    <Input
                      type="number"
                      value={selectedParentId}
                      fluid
                    />
                  )}
                  {filteredItems.length > 0 && !selectedParentId && (
                    <List celled relaxed style={{ border: '1px solid #ddd', borderRadius: '4px', width: '35em', position: 'absolute', zIndex: '1000', background: 'white' }}>
                      {filteredItems.map(item => (
                        <List.Item
                          key={item.key}
                          onClick={() => handleParentChange(item)}
                          style={{ cursor: 'pointer', height: '3em', paddingTop: '10px' }}
                        >
                          <List.Content>
                            <List.Header>{item.text}</List.Header>
                          </List.Content>
                        </List.Item>
                      ))}
                    </List>
                  )}
                </Form.Field>
                <Form.Field>
                    <label>
                      {t(
                          "MANUAL_CREATE_QUESTIONNAIRE_SELECT_ASSOCIATED_TRIGGER", "Associate Trigger"
                      )}
                    </label>
                    <Dropdown
                        placeholder={t("MANUAL_CREATE_QUESTIONNAIRE_DROPDOWN_LIST_PLACEHOLDER", "Select Trigger")}
                        onChange={handleTriggerChange}
                        fluid
                        selection
                        error={!isTriggerValid}
                        options={triggerOptions}
                    />
                    {buildModuleCheckboxes()}
                  </Form.Field>
                  {hasViewSubjectVisitDatesPermission && (
                    <Form.Field>
                      <label>
                        {t(
                          "MANUAL_CREATE_QUESTIONNAIRE_SELECT_ASSOCIATED_VISIT", "Associate visit"
                        )}
                      </label>
                      <Dropdown
                          placeholder={t("MANUAL_CREATE_QUESTIONNAIRE_SELECT_ASSOCIATED_VISIT_DROPDOWNLIST_PLACEHOLDER", "Select visit")}
                          onChange={(_e, data) => {
                            setSelectedVisitid(data?.value);
                          }}
                          fluid
                          selection
                          options={visitListOptions}
                          value={selectedVisitId || 'NONE_SELECTED'}
                      />
                    </Form.Field>
                  )}
                  {definition.questions.map((question) => {
                    if (question.config.isCustomisable) {
                      const questionCustomLabel = customLabels.find((label) => {
                        return label.code === question.code;
                      });

                      if (!questionCustomLabel) return null;
                      return (
                          <Form.Field key={"customLabel_" + question.code}>
                            <label>{question.label}</label>
                            <TextArea
                                onChange={(e) => handleCustomLabelChange(question.code, e.target.value)}
                                value={questionCustomLabel.label}
                            />
                          </Form.Field>
                      );
                    } else {
                      return null;
                    }
                  })}
                </>
              </Segment>
            </Grid.Column>
            <Grid.Row>
              <Grid.Column>
                <Button
                    type="submit"
                    disabled={(isSubmitting || !areCustomLabelsComplete)}
                    primary
                >
                  {t("GLOBAL_BUTTON_SAVE")}
                </Button>
                <Button primary basic type="button" onClick={handleCancel}>
                  {t("GLOBAL_BUTTON_CANCEL")}
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>}
      </Page>
  );

}

const mapStateToProps = (state) => {
  return {
    definitions: getDefinitions(state),
  };
};

const enhance = compose(connect(mapStateToProps), withTranslation());

export default enhance(SubjectCreateQuestionnairePage);
