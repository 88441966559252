import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Button, Form, Grid, Icon, Loader, Message, Table } from "semantic-ui-react";
import Page from "../../components/page/Page";
import QuestionnaireDefinitionService from "../../services/admin/QuestionnaireDefinitionService";
import AdminQuestionnaireDefinitionViewModal from "../../components/admin/AdminQuestionnaireDefinitionViewModal";
import { Link } from "react-router-dom";
import FileSaver from 'file-saver';
import AdminQuestionnaireDefinitionDuplicateModal from "../../components/admin/AdminQuestionnaireDefinitionDuplicateModal";
import LocalStorageHelper from "../../helpers/LocalStorageHelper";
import { LOCALDATA_KEYS } from "../../services/LocalDataService";
import StaffPermissionService from "../../services/StaffPermissionService";
import { compose } from "redux";
import withPermissionWrapper from "../../security/withPermissionWrapper";
import DebouncedTextInput from "../../components/generic/DebouncedTextInput";

const AdminQuestionnaireDefinitionListPage = (props) => {
  const { t } = props;
  const CURRENT_SEARCH_CRITERIA_OBJECT_VERSION = 1;

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [questionnaireDefinitions, setQuestionnaireDefinitions] = useState([]);
  const [selected, setSelected] = useState(null);
  const [toClone,setToClone] = useState(null);
  const [jsonExporting,setJsonExporting] = useState(false);

  const persistSearchCriteriaToLocalStorage = (criteria) => {
    LocalStorageHelper.setJson(
      LOCALDATA_KEYS.QUESTIONNAIRE_DEFINITION_LIST_SEARCH_CRITERIA,
      criteria
    );
  }

  const getInitialSearchCriteria = () => {
    const criteriaTemplate = {
      objectVersion: CURRENT_SEARCH_CRITERIA_OBJECT_VERSION,
      searchTerm: null
    };

    // Get from query arg
    const { location } = props;
    const searchParams = new URLSearchParams(location.search);
    const querySearchTerm = searchParams.get('searchTerm');

    if (querySearchTerm != null && querySearchTerm.length > 0) {
      const updatedCriteria = { ...criteriaTemplate, searchTerm: querySearchTerm };
      persistSearchCriteriaToLocalStorage(updatedCriteria)
      return updatedCriteria;
    }

    // Persisted from previous search
    const persistedCriteria = LocalStorageHelper.getJsonObject(
      LOCALDATA_KEYS.QUESTIONNAIRE_DEFINITION_LIST_SEARCH_CRITERIA
    );
    if (persistedCriteria.objectVersion === CURRENT_SEARCH_CRITERIA_OBJECT_VERSION) {
      return { ...persistedCriteria };
    }

    return criteriaTemplate;
  }

  const [searchCriteria, setSearchCriteria] = useState(getInitialSearchCriteria());

  const performSearch = async (searchTerm) => {    
    setLoading(true);
    try {
      setQuestionnaireDefinitions([]);
      const questionnaireDefinitionList =
        await QuestionnaireDefinitionService.getQuestionnaireDefinitions(searchTerm);
      if (questionnaireDefinitionList) {
        setQuestionnaireDefinitions(questionnaireDefinitionList);
        setError(false);
      } else {
        setError(true);
      }

      setLoading(false);
    } catch (e) {
      console.error("Error fetching questionnaire definitions: ", e);
      setError(true);
    }
  };

  const handleQueryChange = async (_e, data) => {
    saveSearchCriteria(data.value || '');
  };

  useEffect(() => {
    if (searchCriteria?.searchTerm == null)  {
      return;
    }
    performSearch(searchCriteria?.searchTerm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchCriteria]);

  const handleFormSubmit = () => {
    performSearch(searchCriteria?.searchTerm);
  };

  const createTable = (questionnaireDefinitions) => {
    if (!questionnaireDefinitions) {
      return;
    }

    const headers = [
      {
        title: ["ADMIN_QUESTIONNAIRE_DEFINITION_TABLE_HEADER_CODE", "Code"],
        config: { width: 4 },
      },
      {
        title: ["ADMIN_QUESTIONNAIRE_DEFINITION_TABLE_HEADER_TYPE", "Type"],
        config: { width: 3 },
      },
      {
        title: [
          "ADMIN_QUESTIONNAIRE_DEFINITION_TABLE_HEADER_SEQUENCE",
          "Sequence",
        ],
        config: { width: 2 },
      },
      {
        title: [
          "ADMIN_QUESTIONNAIRE_DEFINITION_TABLE_HEADER_RESTRICTTO",
          "Restrict to",
        ],
        config: { width: 2 },
      },
      {
        title: [
          "ADMIN_QUESTIONNAIRE_DEFINITION_TABLE_HEADER_NONSCORING",
          "Non Scoring",
        ],
        config: { width: 2 },
      },
      {
        title: [
          "ADMIN_QUESTIONNAIRE_DEFINITION_TABLE_HEADER_SCORECALCULATION",
          "Score Calculation",
        ],
        config: { width: 4 },
      },
      {
        title: [
          "ADMIN_QUESTIONNAIRE_DEFINITION_TABLE_HEADER_ACTION",
          "Action",
        ],
        config: { width: 2 },
      },
    ];

    const cellStyle = { textOverflow: "elipses", whiteSpace: "nowrap" };

    const headerRow = (
      <Table.Header>
        <Table.Row>
          {headers.map((header) => (
            <Table.HeaderCell {...header?.config} key={header.title}>
              {t(header.title)}
            </Table.HeaderCell>
          ))}
        </Table.Row>
      </Table.Header>
    );
    const questionnaireDefinitionRows = questionnaireDefinitions.map(
      (questionnaireDefinition, index) => (
        <Table.Row
          key={questionnaireDefinition.id}
          active={questionnaireDefinition.id === selected}
          style={loading ? { backgroundColor: "#eee", color: "#999" } : {}}
          onClick={
            loading ? () => {} : () => setSelected(questionnaireDefinition.id)
          }
        >
          <Table.Cell key={`${questionnaireDefinition.id}_code`}>{questionnaireDefinition.code}</Table.Cell>
          <Table.Cell key={`${questionnaireDefinition.id}_type`} style={cellStyle}>
            {questionnaireDefinition.type}
          </Table.Cell>
          <Table.Cell key={`${questionnaireDefinition.id}_sequence`} style={cellStyle}>
            {questionnaireDefinition.sequence}
          </Table.Cell>
          <Table.Cell key={`${questionnaireDefinition.id}_restrictTo`} style={cellStyle}>
            {questionnaireDefinition.restrictTo != null ? questionnaireDefinition.restrictTo : 'ANY'}
          </Table.Cell>
          <Table.Cell key={`${questionnaireDefinition.id}_nonscoring`} style={cellStyle}>
            {questionnaireDefinition.nonscoring}
          </Table.Cell>
          <Table.Cell key={`${questionnaireDefinition.id}_scoreCalc`}>{questionnaireDefinition.scoreCalculation}</Table.Cell>
          <Table.Cell key={`${questionnaireDefinition.id}_ctl`}>
              <Icon style={{cursor: "pointer"}} color="orange" onClick={(e) => {e.stopPropagation(); setToClone(questionnaireDefinition.code) }} name='copy' />
              <Icon style={{cursor: "pointer"}} color={jsonExporting ? "grey" : "orange" } inverted={jsonExporting} disabled={jsonExporting} onClick={(e) => {e.stopPropagation(); exportToJson(questionnaireDefinition.code)}} name='download' />
              <Button
                  primary
                  fluid
                  size="tiny"
                  as={Link}
                  to={
                    "/app/utils/questionnaire-definitions/full-edit/" +
                    questionnaireDefinition.id
                  }
              >
                {t("ADMIN_QUESTIONNAIERDEFINITION_Edit", "Edit")}
              </Button>
          </Table.Cell>
        </Table.Row>
      )
    );
    return (
      <>
        {headerRow}
        <Table.Body>
          {questionnaireDefinitionRows}
        </Table.Body>
      </>
    );
  };

  const exportToJson = async (code) => {
    setJsonExporting(true);
    try{
      const jsonContent = await QuestionnaireDefinitionService.getQuestionnaireDefinitionJsonExport(code)
      const blob = new Blob([JSON.stringify(jsonContent,null, 2)], {
        type: "text/plain;charset=utf-8"
      });
      let jsDate = new Date();
      //jsDate.setTime( jsDate.getTime() + jsDate.getTimezoneOffset() * 60 * 1000 );
      FileSaver.saveAs(blob, "qd-export-" + jsDate.toISOString().slice(0, 19) + "-" + code + ".json");
      setJsonExporting(false);
    }catch(response){
      setJsonExporting(false);
      console.log("Error exporting JSON:" + response);
    }
  }

  const saveSearchCriteria = (searchTerm) => {
    const criteria = { ...searchCriteria, searchTerm };
    setSearchCriteria(criteria);
    persistSearchCriteriaToLocalStorage(criteria);
  }

  const setAutoSearchValue = (value) => {
    saveSearchCriteria(value);
  }

  return (
    <Page
      name="Admin Questionnaire Definition Utilities"
      header={t(
        "ADMIN_QUESTIONNAIRE_DEFINITIONS_HEADER",
        "Admin Questionnaire Definitions"
      )}
    >
      {error && (
        <Message
          error
          header={t("GLOBAL_ERROR_TITLE", "Error")}
          content={"Error, if it persist contact support:" + error.message}
        />
      )}
      {loading && questionnaireDefinitions.length === 0 && (
        <Loader active={loading} />
      )}
      {Array.isArray(questionnaireDefinitions) && (
        <>
          <Form onSubmit={handleFormSubmit}>
          <Grid>
            <Grid.Row>
              <Grid.Column width={11}>
              <Form.Field>
                <DebouncedTextInput
                  icon={<Icon name="search" />}
                  iconPosition={"left"}
                  value={searchCriteria?.searchTerm ?? ''}
                  onChange={handleQueryChange}
                />
              </Form.Field>
            </Grid.Column>
              <Grid.Column width={5}>
                <Button
                  as={Link}
                  to="/app/utils/questionnaire-definitions/new"
                  fluid
                  primary
                >
                {t("ADMIN_QUESTIONNAIERDEFINITION_NEW", "Create New")}
                </Button>
              </Grid.Column>
            </Grid.Row>
            </Grid>
          </Form>
              
          <Table fixed id={"questionnaireDefinitionsList"}>
            {createTable(questionnaireDefinitions)}
          </Table>
          <AdminQuestionnaireDefinitionViewModal
            questionnaireDefinitionId={selected}
            isOpen={selected !== null}
            setIsOpen={() => setSelected(null)}
          />
          <AdminQuestionnaireDefinitionDuplicateModal
            questionnaireDefinitionCode={toClone}
            isOpen={toClone !== null}
            setIsOpen={() => {setToClone(null)}}
            setSearchTermCallback={setAutoSearchValue}
          />
        </>
      )}
    </Page>
  );
};

const withEnhancements = (options) => compose(
  withPermissionWrapper(options),
  withTranslation()
);
export default withEnhancements({ permissionFunctionDelegate: StaffPermissionService.canManageQuestionnaireDefinitions })(AdminQuestionnaireDefinitionListPage);
